import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {LocalStorageService} from "../services/local-storage.service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const token = localStorageService.getLocalStorage('userToken');
  const authReq = token ? req.clone({setHeaders: {Authorization: 'Bearer ' + token + ''}}) : req;
  return next(authReq);
};
