import {Routes} from '@angular/router';
import {languageGuard} from "./shared/guards/language.guard";
import {customerGuard} from "./shared/guards/customer.guard";
import {userGuard} from "./shared/guards/user.guard";
import {TestComponent} from "./modules/test/test.component";
import {DashboardComponent} from "./modules/dashboard/dashboard.component";
import {PageNotFoundComponent} from "./modules/helper/page-not-found/page-not-found.component";

export const routes: Routes = [
  // redirects
  {path: '', redirectTo: '/auth/customer', pathMatch: 'full'},
  {path: 'customer/:customerKey', redirectTo: '/auth/customer/:customerKey', pathMatch: 'full'},

  // identical to app
  {path: "auth", loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)},
  {path: "dashboard", component: DashboardComponent, title: 'DASHBOARD', canActivate: [languageGuard, customerGuard, userGuard]},
  {path: "intranet", loadChildren: () => import('./modules/intranet/intranet.module').then((m) => m.IntranetModule)},

  // helper
  {path: 'test', component: TestComponent}, // TODO remove before live
  {path: '**', component: PageNotFoundComponent, title: 'PAGENOTFOUND', canActivate: [languageGuard]},
];
