import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import * as Sentry from "@sentry/angular";
import {environment} from './environments/environment';

// Sentry integration start
Sentry.init({
  dsn: "https://841dda43b2accf55246bd11adf43fae6@o421108.ingest.us.sentry.io/4507662864809984",
  environment: environment.production ? "production" : "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/web\.max-toolbox\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Sentry integration end

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
