import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LoadingIndicatorComponent} from "./shared/elements/loading-indicator/loading-indicator.component";
import {LayoutService} from "./shared/services/layout.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {LanguageService} from "./shared/services/language.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingIndicatorComponent, AsyncPipe, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    private layoutService: LayoutService
  ) {
  }

  public ngOnInit(): void {
  }
}
