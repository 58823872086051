import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, Router, TitleStrategy} from '@angular/router';
import {routes} from './app.routes';
import * as Sentry from "@sentry/angular";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig} from "@angular/common";
import {environment} from "../environments/environment";
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {TitleService} from "./shared/services/title.service";
import {loadingIndicatorInterceptor} from "./shared/interceptors/loading-indicator.interceptor";
import {authInterceptor} from "./shared/interceptors/auth.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([authInterceptor, loadingIndicatorInterceptor])
    ),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    // Sentry error handling
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    // Image loader
    // https://angular.dev/guide/image-optimization
    // https://blogs.halodoc.io/revolutionize-image-loading-in-angular-unleash-peak-performance-with-ngoptimizedimage-directive-mastery/
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [480, 800, 1440],
      }
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.src.indexOf('/files') === -1) {
          if (config.width) {
            return config.src.replace('.png', '_' + config.width + '.png');
          }
          return config.src;
        }

        // can't inject services here, so we need to use localStorage directly
        const token = localStorage.getItem('userToken');
        return 'https://webapi.max-toolbox.com' + config.src + (token && config.width ? '?width=' + config.width + '&token=' + token : token && !config.width ? '?token=' + token : !token && config.width ? '?width=' + config.width : '');
        //return (Config.DEFAULT_STORE_STRING === 'store' ? environment.apiUrlStore : environment.apiUrlDefault) + config.src + (token && config.width ? '?width=' + config.width + '&token=' + token : token && !config.width ? '?token=' + token : !token && config.width ? '?width=' + config.width : '');
      }
    },

    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideMessaging(() => getMessaging()),
    // Page title
    {provide: TitleStrategy, useClass: TitleService}
  ]
};
