
<app-bar-top></app-bar-top>

<!--<img
  [ngSrc]="'/assets/images/backgrounds/default_light.png'"
  [width]="200"
  [height]="200"
  [sizes]="'100vw, 100vw, 100vw'"
  [alt]="'HSIMAGE' | translate"
  class="img-fluid"
  priority="low"
/>

<app-bar-top></app-bar-top>
<app-icon-badge [style]="'dashboard'" [orientation]="'bottomRight'" [icon]="'calendar_view_day'" [badge]="82" [title]="'Wall'" (click)="onClick()"></app-icon-badge>
<app-bar-footer></app-bar-footer>-->




  <div id="app-test" class="main-container d-flex p-2 p-md-3" role="main" [attr.aria-label]="('CUSTOMERPAGE' | translate)">
    <div class="inner-container w-100 h-100 d-flex flex-column flex-lg-row overflow-hidden custom-scrollbar">
      <!-- Right Column for Logo -->
      <div class="col-xl-8 col-lg-7 col-md-12 d-flex flex-column justify-content-center align-items-center order-1 order-lg-3 custom-scrollbar p-sm-2 p-md-3">
        <div class="scroll-wrapper w-100">
          <div class="dsb-container w-100 mx-auto">
            <!-- Headline that appears when columns are wrapping -->
            <div class="d-flex flex-column justify-content-center align-items-center d-lg-none mb-3">
              <!-- Centered Logo -->
              <div class="logo mb-4">
                <img [ngSrc]="layoutService.getTheme() === 'dark' ? '/assets/images/logos/humanstarsFullWhite.png':'/assets/images/logos/humanstarsFullGrey.png'" [width]="300" [height]="107" [alt]="('HSIMAGE' | translate)" class="img-fluid logo" priority />
              </div>

              <!-- Row with Two Equal Columns -->
              <div class="d-flex align-items-center">
                <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                  <img [ngSrc]="userService.getUser()!.avatar" [width]="85" [height]="85" [sizes]="'100vw, 100vw, 100vw'" [alt]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" priority="low" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <h4>Good morning, Clair</h4>
                  <p class="m-0">Tuesday 26, Apr</p>
                </div>
              </div>
            </div>
            <!-- Top Section: Settings Icon -->
            <div class="d-flex justify-content-end mb-3">
              <button class="btn btn-link text-muted" (click)="onFakeMenuOpen()">
                <span class="mts-icon">tune</span>
              </button>
            </div>

            <!-- Tab Content for Icons -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="page1" role="tabpanel">
                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                  <div class="d-flex flex-column align-items-center">
                    <div class="dash-icon" (click)="onFakeClick()">
                      <div class="icon-container bottom-right position-relative">
                        <div class="background-layer"></div>
                        <div class="foreground-layer">
                          <div class="icon">
                            <span class="mts-icon">calendar_view_day</span>
                          </div>
                        </div>
                        <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                      </div>
                      <div class="icon-text text-center">Wall</div>
                    </div>
                  </div>
                  <div class="dash-icon light-purple d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container top-right position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">cycle</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Feedback</div>
                  </div>
                  <div class="dash-icon pink d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container bottom-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">rebase</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Workflow</div>
                  </div>
                  <div class="dash-icon orange d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container top-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">psychology</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Knowlidge Base</div>
                  </div>
                  <div class="dash-icon aqua d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container bottom-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">chat</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Chat</div>
                  </div>
                  <div class="dash-icon yellow d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container top-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">forum</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Community</div>
                  </div>
                  <div class="dash-icon blue d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container bottom-right position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">videocam</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Meetings</div>
                  </div>
                  <div class="dash-icon dark-orange d-flex flex-column align-items-center" (click)="onFakeClick()">
                    <div class="icon-container top-right position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">import_contacts</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Contacts</div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="page2" role="tabpanel">
                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                  <div class="dash-icon cyan d-flex flex-column align-items-center">
                    <div class="icon-container bottom-right position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">tv</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                    </div>
                    <div class="icon-text text-center">TV</div>
                  </div>
                  <div class="dash-icon purple d-flex flex-column align-items-center">
                    <div class="icon-container top-right position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">perm_contact_calendar</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Profile</div>
                  </div>
                  <div class="dash-icon dark-green d-flex flex-column align-items-center">
                    <div class="icon-container bottom-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">person</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Users</div>
                  </div>
                  <div class="dash-icon sky-blue d-flex flex-column align-items-center">
                    <div class="icon-container top-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">group</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Groups</div>
                  </div>
                  <div class="dash-icon red d-flex flex-column align-items-center">
                    <div class="icon-container bottom-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">settings</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Settings</div>
                  </div>
                  <div class="dash-icon light-green d-flex flex-column align-items-center">
                    <div class="icon-container top-left position-relative">
                      <div class="background-layer"></div>
                      <div class="foreground-layer">
                        <div class="icon">
                          <span class="mts-icon">folder</span>
                        </div>
                      </div>
                      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                    </div>
                    <div class="icon-text text-center">Document Manager</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Tab Navigation for Pagination -->
            <div class="d-flex justify-content-center mt-3">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" id="page1-tab" data-bs-toggle="tab" href="#page1" role="tab">1</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="page2-tab" data-bs-toggle="tab" href="#page2" role="tab">2</a>
                </li>
              </ul>
            </div>

            <!-- Settings -->
            <div class="d-flex justify-content-end mt-3 mb-2">
              <div class="dropdown">
                <button class="btn btn-link p-0 text-muted" id="linkManagement" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="mts-icon">tune</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="linkManagement">
                  <li><a class="dropdown-item" href="#">Manage Links</a></li>
                  <li><a class="dropdown-item" href="#">Add Link</a></li>
                </ul>
              </div>
            </div>

            <!-- Links -->
            <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-center g-2 p-2 p-sm-0 p-lg-2">
              <div class="d-flex">
                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                  <div class="icon-background">
                    <span class="mts-icon">link</span>
                  </div>
                  <span class="link-text">Covid langer Text damit</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                  <div class="icon-background">
                    <span class="mts-icon">link</span>
                  </div>
                  <span class="link-text">LEER</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                  <div class="icon-background">
                    <span class="mts-icon">link</span>
                  </div>
                  <span class="link-text">WM Tippspiel</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                  <div class="icon-background">
                    <span class="mts-icon">link</span>
                  </div>
                  <span class="link-text">WM Tippspiel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Left Column for Form -->
      <div class="col-xl-4 col-lg-5 col-md-12 d-flex flex-column justify-content-center align-items-center order-2 order-lg-1 pe-md-2">
        <div class="scroll-wrapper w-100 h-100">
          <div class="dsb-container w-100 mx-auto">
            <!-- Headline that appears when columns are NOT wrapping -->
            <div class="d-none flex-column justify-content-center align-items-center d-lg-flex mb-3 p-2">
              <!-- Centered Logo -->
              <div class="logo mb-4">
                <img [ngSrc]="layoutService.getTheme() === 'dark' ? '/assets/images/logos/humanstarsFullWhite.png':'/assets/images/logos/humanstarsFullGrey.png'" [width]="300" [height]="107" [alt]="('HSIMAGE' | translate)" class="img-fluid logo" priority />
              </div>

              <!-- Row with Two Equal Columns -->
              <div class="d-flex align-items-center">
                <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                  <img [ngSrc]="userService.getUser()!.avatar" [width]="85" [height]="85" [sizes]="'100vw, 100vw, 100vw'" [alt]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" priority="low" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5>Good morning, Clair</h5>
                  <p class="m-0">Tuesday 26, Apr</p>
                </div>
              </div>
            </div>
            <div class="custom-scrollbar limit-height p-2">
              <div class="calendar-event w-border card shadow-sm rounded-3 mb-2">
                <div class="card-body">
                  <!-- Header -->
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <h6 class="m-0">Event Calendar</h6>
                    <div class="d-flex align-items-center">
                      <button class="btn btn-link text-muted p-0 me-2">
                        <div class="mts-icon">chevron_left</div>
                      </button>
                      <button class="btn btn-link text-muted p-0 me-3">
                        <div class="mts-icon">chevron_right</div>
                      </button>
                      <div class="d-flex align-items-center">
                        <button class="btn btn-link text-muted p-0 me-3">
                          <div class="mts-icon">event</div>
                          <span>Apr</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Days of the Week -->
                  <div class="d-flex justify-content-between align-items-center mb-2">
                    <div class="text-center day-container">
                      <small class="text-muted">Mon</small>
                      <div class="fw-bold py-1">23</div>
                      <div class="dots-no-bg">
                        <span class="dot"></span>
                      </div>
                    </div>
                    <div class="text-center day-container">
                      <small class="text-muted">Tue</small>
                      <div class="fw-bold py-1">24</div>
                      <div class="dots-no-bg">
                        <span class="dot bg-waring"></span>
                        <span class="dot bg-success"></span>
                      </div>
                    </div>
                    <div class="text-center day-container">
                      <small class="text-muted">Wed</small>
                      <div class="fw-bold py-1">25</div>
                      <div class="dots-no-bg">
                        <span class="dot bg-success"></span>
                      </div>
                    </div>
                    <!-- Highlighted Current Day -->
                    <div class="text-center current-day day-container">
                      <small class="text-white">Thu</small>
                      <div class="fw-bold text-white rounded px-2 py-1">26</div>
                      <div class="dots-container-inverted">
                        <span class="dot bg-danger"></span>
                        <span class="dot bg-success"></span>
                        <span class="dot bg-warning"></span>
                      </div>
                    </div>
                    <div class="text-center day-container">
                      <small class="text-muted">Fri</small>
                      <div class="fw-bold py-1">27</div>
                      <div class="dots-no-bg">
                        <span class="dot bg-warning"></span>
                        <span class="dot bg-danger"></span>
                      </div>
                    </div>
                    <div class="text-center day-container">
                      <small class="text-muted">Sat</small>
                      <div class="fw-bold py-1">28</div>
                      <div class="dots-no-bg">
                        <span class="dot bg-danger"></span>
                        <span class="dot bg-warning"></span>
                        <span class="dot bg-success"></span>
                      </div>
                    </div>
                    <div class="text-center day-container">
                      <small class="text-muted">Sun</small>
                      <div class="fw-bold py-1">29</div>
                      <div class="dots-no-bg">
                        <span class="dot bg-danger"></span>
                        <span class="dot bg-warning"></span>
                      </div>
                    </div>
                  </div>

                  <!-- Options Menu for Days of the Week -->
                  <div class="d-flex justify-content-end">
                    <div class="dropdown">
                      <button class="btn btn-link p-0 text-muted" id="event-calendar-menu" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="mts-icon">more_horiz</span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-calendar-menu">
                        <li><a class="dropdown-item" href="#">Add Event</a></li>
                        <li><a class="dropdown-item" href="#">Show all Events</a></li>
                      </ul>
                    </div>
                  </div>

                  <!-- Events -->
                  <div class="row eventContainer danger">
                    <div class="d-flex justify-content-between align-items-center mb-1 mt-3">
                      <div class="event-details flex-grow-1 d-flex">
                        <div class="event-bar"></div>
                        <div class="ms-2">
                          <h6 class="fw-bold event-title mb-1">Urgent meeting</h6>
                          <p class="small text-muted mb-0">
                            Discussing the problems related to the application bugs and clarifying the process of testing the application.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center gx-2 ps-3">
                      <div class="col-auto d-flex align-items-center">
                        <span class="mts-icon">schedule</span>
                        <span class="fs-verysmall ps-1">10:00 - 11:30</span>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <button class="btn btn-link text-muted d-flex align-items-center p-0 me-3">
                          <span class="mts-icon">person</span>
                          <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                        </button>
                      </div>
                      <div class="col-auto flex-grow-1 text-end">
                        <div class="dropdown">
                          <button class="btn btn-link p-0 text-muted" id="event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mts-icon">more_horiz</span>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-menu">
                            <li><a class="dropdown-item" href="#">Share</a></li>
                            <li><a class="dropdown-item" href="#">Edit</a></li>
                            <li><a class="dropdown-item" href="#">Remove</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card shadow-sm rounded-3 mb-2 w-border recent-notifications">
                <div class="card-body">
                  <div class="d-flex-column">
                    <div class="d-flex align-items-start">
                      <div class="d-flex e-avatar e-avatar-circle e-avatar-xlarge">
                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                      </div>

                      <div class="col ps-2">
                        <div class="d-flex flex-wrap align-items-center">
                          <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                          <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                        </div>

                        <p class="fs-verysmall text-muted mb-1">
                          Discussing the problems related to the application bugs and clarifying the process of testing the application...
                        </p>
                        <div class="row align-items-center gx-2">
                          <div class="col-auto d-flex align-items-center">
                            <span class="badge bg-primary rounded-pill">CHANNELS</span>
                          </div>
                          <div class="col-auto flex-grow-1 text-end">
                            <div class="dropdown">
                              <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="mts-icon">more_horiz</span>
                              </button>
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                                <li><a class="dropdown-item" href="#">Mark as read</a></li>
                                <li><a class="dropdown-item" href="#">Delete</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>






<app-bar-footer></app-bar-footer>
