<div class="d-flex">
  <ng-container *ngIf="(date | date: 'shortDate') === (today | date: 'shortDate')">
    <p class="fs-small">{{ 'TODAY' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="(date | date: 'shortDate') === (yesterday | date: 'shortDate')">
    <p class="fs-small">{{ 'YESTERDAY' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="(date | date: 'shortDate') !== (today | date: 'shortDate') && (date | date: 'shortDate') !== (yesterday | date: 'shortDate')">
    <p class="fs-small">{{ date | date: 'shortDate' }}</p>
  </ng-container>
</div>
