import {Component} from '@angular/core';
import {DatePipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IconBadgeComponent} from "../icon-badge/icon-badge.component";
import {TranslatePipe} from "../../pipes/translate.pipe";
import {UserService} from "../../services/user.service";
import {LinkComponent} from "../link/link.component";
import {RouterService} from "../../services/router.service";
import {FormButtonComponent} from "../form-button/form-button.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../services/api.service";
import {ErrorService} from "../../services/error.service";
import {LanguageService} from "../../services/language.service";
import {NotificationComponent} from "../notification/notification.component";
import {TYPE} from "../../types/types";
import {HeadlineComponent} from "../headline/headline.component";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {WarningComponent} from "../warning/warning.component";
import {DividerDateComponent} from "../divider-date/divider-date.component";

@Component({
  selector: 'app-bar-top',
  standalone: true,
  imports: [
    NgIf,
    IconBadgeComponent,
    TranslatePipe,
    NgOptimizedImage,
    LinkComponent,
    FormButtonComponent,
    NotificationComponent,
    NgForOf,
    HeadlineComponent,
    DropDownListModule,
    WarningComponent,
    DividerDateComponent,
    DatePipe
  ],
  templateUrl: './bar-top.component.html',
  styleUrl: './bar-top.component.scss'
})
export class BarTopComponent {

  public tab: 'notifications'|'chat'|null = null;
  public status: 'new'|'read' = 'new';
  public messages: TYPE.Notification[] = [];
  private page: number = 1;
  private module: 'calendar'|'channel'|'chat'|'intranet'|'mapp'|'meeting'|'survey'|'workflow'|null = null;
  private subscriptions: Subscription[] = [];


  //Small Filter dropdown
  public statusData: { [key: string]: Object }[] = [
    { text: 'All', id: 'all'},
    { text: 'Wall', id: 'wall' },
    { text: 'Wiki', id: 'channel' },
    { text: 'Feedback', id: 'survey' },
    { text: 'Workflow', id: 'workflow' }
  ];
  public fields: Object = { text: 'text', value: 'id' };
  public selectedValue: string = 'all';

  public constructor(
    public userService: UserService,
    private routerService: RouterService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private languageService: LanguageService
  ) {
    console.log(userService.getUser());
  }

  public openPopup(type: 'language' | 'profile' | 'settings' | 'push'): void {
    // TODO: implement all the popups, maybe by service
    console.log('open popup: ' + type);
  }

  public signOut(): void {
    this.userService.resetUser();
    this.routerService.navigateTo('/auth/login');
  }

  public setTab(tab: 'notifications'|'chat'): void {
    this.tab = (tab === this.tab ? null : tab);
    this.page = 1;
    this.status = 'new';
    this.module = (this.tab === 'chat' ? 'chat' : null);
    if (this.tab) {
      this.load();
    }
  }

  public setStatus(status: 'new'|'read'): void {
    this.status = status;
    this.page = 1;
    this.load();
  }

  public setModule(module: 'calendar'|'channel'|'chat'|'intranet'|'mapp'|'meeting'|'survey'|'workflow'|null): void {
    this.module = module;
    this.page = 1;
    this.load();

    // TODO HTML integration
  }

  public setRead(): void {
    this.subscriptions.push(this.apiService.setBellNotedAll().subscribe({
      next: (success) => {
        this.page = 1;
        this.load();
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }

  private load(): void {
    // TODO implement bell load
    console.log('Tab: ' + this.tab + ', Status: ' + this.status + ', Module: ' + this.module + ', Page: ' + this.page);

    this.subscriptions.push(this.apiService.getBellLoad(this.page, this.status, this.module).subscribe({
      next: (messages) => {
        if (this.page === 1) {
          this.messages = messages;
        } else {
          this.messages = this.messages.concat(messages);
        }
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }



  onFilterChange(event: any) {
    console.log('Selected Value:', event.itemData);
    console.log('Selected Text:', event.itemData.text);
    console.log('Selected ID:', event.itemData.id);
  }

}
