import {Component, Input} from '@angular/core';
import {DatePipe, NgIf} from "@angular/common";
import {TranslatePipe} from "../../pipes/translate.pipe";

@Component({
  selector: 'app-divider-date',
  standalone: true,
  imports: [
    DatePipe,
    TranslatePipe,
    NgIf
  ],
  templateUrl: './divider-date.component.html',
  styleUrl: './divider-date.component.scss'
})
export class DividerDateComponent {
  @Input() date: Date = new Date();

  public today: Date = new Date();
  public yesterday: Date = new Date(this.today.setDate(this.today.getDate() - 1));
}
