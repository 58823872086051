import {Component} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-loading-indicator',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf
  ],
  templateUrl: './loading-indicator.component.html',
  styleUrl: './loading-indicator.component.scss'
})
export class LoadingIndicatorComponent {

  constructor(
    public loadingService: LoadingService
  ) {

  }

  protected readonly LoadingService = LoadingService;
}
