<ng-container *ngIf="!(loadingService.loadingIndicator$ | async)">
    <div class="icon-list-container position-relative">
        <div class="icon-list position-fixed top-0 end-0 p-1">
            <!-- Notification Bell -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative" (click)="toggleNotificationWindow()">
                <span class="mts-icon">notifications</span>
                <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                    <span class="visually-hidden">New alerts</span>
                </span>
            </button>
        
            <!-- Message Icon -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative" (click)="toggleChatNotificationWindow()">
                <span class="mts-icon">chat_bubble</span>
            <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                <span class="visually-hidden">New messages</span>
            </span>
            </button>
        
            <!-- Avatar Dropdown -->
            <div class="dropdown d-inline">
            <button type="button" class="btn btn-link p-1 text-muted dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                <div class="e-avatar e-avatar-circle e-avatar-xsmall">
                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                </div>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="#">Language</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item" href="#">Notifications</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
            </div>

            <!-- Notification Window -->
            <div *ngIf="showNotifications" class="notification-window shadow-lg p-3 d-flex flex-column">
                <div class="d-flex">
                    <button ejs-button type="button" class="e-btn e-primary w-100 mx-1">New</button>
                    <button ejs-button type="button" class="e-btn e-outline e-secondary w-100 mx-1">Read</button>
                </div>

                <div class="d-flex justify-content-between align-items-center mt-3">
                    <h6 class="mb-0">Notifications</h6>
                    <div class="dropdown">
                        <button class="btn btn-link p-0 text-muted" id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mts-icon">more_vert</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
                            <li><a class="dropdown-item" href="#">Mark all as read</a></li>
                        </ul>
                    </div>
                </div>
                <div class="alert alert-warning d-flex align-items-center p-2" role="alert">
                    <span class="mts-icon mx-2">warning</span>
                    <div class="fs-verysmall">
                        You have custom notifications active and might not receive all notifications. 
                        <a href="#" class="alert-link">Edit notification preferences.</a>
                    </div>
                </div>
                  
                <!-- Dropdown List with Icon -->
                <div class=" row justify-content-end">
                    <div class="col-md-6 small-filter-dropdown">
                        <ejs-dropdownlist [dataSource]="statusData" [fields]="fields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="selectedValue" [valueTemplate]='valueTemplate' cssClass="filter-dropdown" (change)="onFilterChange($event)" >
                            <ng-template #valueTemplate let-data="">
                                <div class="d-flex align-items-center text-muted">
                                    <span class="mts-icon p-1">filter_list</span>
                                    <span class='value fs-small'>{{data.text}}</span>
                                </div>
                            </ng-template>
                        </ejs-dropdownlist>
                    </div>
                  </div>
                <div class="custom-scrollbar">
                    <div class="d-flex">
                        <p class="fs-small">Today</p>
                    </div>
                    
                    <!-- Notification List -->
                    <div class="card shadow-sm w-border rounded-3 mb-2 bell-notifications" (click)="handleBellCardClick($event)">
                        <div class="card-body">
                            <div class="d-flex-column">
                                <div class="d-flex align-items-start">
                                    <div class="d-flex e-avatar e-avatar-circle e-avatar-xlarge">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                    </div>
                                    
                                    <div class="col ps-2">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                            <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                        </div>
                                        
                                        <p class="fs-verysmall text-muted mb-1">
                                        Discussing the problems related to the...
                                        </p>
                                        
                                        <div class="row align-items-center gx-2">
                                            <div class="col-auto d-flex align-items-center">
                                                <span class="badge bg-primary rounded-pill">CHANNELS</span>
                                            </div>
                                            <div class="col-auto flex-grow-1 text-end">
                                                <div class="dropdown">
                                                    <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="mts-icon">more_horiz</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                                                        <li><a class="dropdown-item" href="#">Mark as read</a></li>
                                                        <li><a class="dropdown-item" href="#">Delete</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <!-- Chat Notification Window -->
            <div *ngIf="showChatNotifications" class="chat-notification-window shadow-lg p-3 d-flex flex-column">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h6 class="mb-0">Chat Notifications</h6>
                    <div class="dropdown">
                        <button class="btn btn-link p-0 text-muted" id="chat-notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mts-icon">more_vert</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chat-notifications-menu">
                            <li><a class="dropdown-item" href="#">Delete all</a></li>
                        </ul>
                    </div>
                </div>

                <div class="custom-scrollbar">
                    <div class="d-flex">
                        <p class="fs-small">Today</p>
                    </div>
                    
                    <!-- Chat Notification List -->
                    <div class="card shadow-sm w-border rounded-3 mb-2 chat-notifications" (click)="handleChatCardClick($event)">
                        <div class="card-body">
                            <div class="d-flex-column">
                                <div class="d-flex align-items-start">
                                    <div class="d-flex e-avatar e-avatar-circle e-avatar-xlarge">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                    </div>
                                    
                                    <div class="col ps-2">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                            <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                        </div>
                                        
                                        <p class="fs-verysmall text-muted mb-1">
                                        Discussing the problems related to the...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="app-test" class="main-container d-flex p-2 p-md-3" role="main" [attr.aria-label]="('CUSTOMERPAGE' | translate)">
        <div class="inner-container w-100 h-100 d-flex flex-column flex-lg-row overflow-hidden custom-scrollbar">
            <!-- Right Column for Logo -->
            <div class="col-xl-8 col-lg-7 col-md-12 d-flex flex-column justify-content-center align-items-center order-1 order-lg-3 custom-scrollbar p-sm-2 p-md-3">
                <div class="scroll-wrapper w-100">
                    <div class="dsb-container w-100 mx-auto">
                        <!-- Headline that appears when columns are wrapping -->
                        <div class="d-flex flex-column justify-content-center align-items-center d-lg-none mb-3">
                            <!-- Centered Logo -->
                            <div class="logo mb-4">
                            <img [ngSrc]="layoutService.getTheme() === 'dark' ? '/assets/images/logos/humanstarsFullWhite.png':'/assets/images/logos/humanstarsFullGrey.png'" [width]="300" [height]="107" [loaderParams]="{width: 300, height: 107}" [alt]="('HSIMAGE' | translate)" class="img-fluid logo" priority />
                            </div>

                            <!-- Row with Two Equal Columns -->
                            <div class="d-flex align-items-center">
                                <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h4>Good morning, Clair</h4>
                                    <p class="m-0">Tuesday 26, Apr</p>
                                </div>
                            </div>
                        </div>
                        <!-- Top Section: Settings Icon -->
                        <div class="d-flex justify-content-end mb-3">
                            <button class="btn btn-link text-muted" (click)="onFakeMenuOpen()">
                                <span class="mts-icon">tune</span>
                            </button>
                        </div>

                        <!-- Tab Content for Icons -->
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="page1" role="tabpanel">
                                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                                    <div class="d-flex flex-column align-items-center">
                                        <div class="dash-icon" (click)="onFakeClick()">
                                            <div class="icon-container bottom-right position-relative">
                                                <div class="background-layer"></div>
                                                <div class="foreground-layer">
                                                    <div class="icon">
                                                        <span class="mts-icon">calendar_view_day</span>
                                                    </div>
                                                </div>
                                                <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                                            </div>
                                            <div class="icon-text text-center">Wall</div>
                                        </div>
                                    </div>
                                    <div class="dash-icon light-purple d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">cycle</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Feedback</div>
                                    </div>
                                    <div class="dash-icon pink d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">rebase</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Workflow</div>
                                    </div>
                                    <div class="dash-icon orange d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">psychology</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Knowlidge Base</div>
                                    </div>
                                    <div class="dash-icon aqua d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">chat</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Chat</div>
                                    </div>
                                    <div class="dash-icon yellow d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">forum</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Community</div>
                                    </div>
                                    <div class="dash-icon blue d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">videocam</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Meetings</div>
                                    </div>
                                    <div class="dash-icon dark-orange d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">import_contacts</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Contacts</div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="page2" role="tabpanel">
                                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                                    <div class="dash-icon cyan d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">tv</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                                        </div>
                                        <div class="icon-text text-center">TV</div>
                                    </div>
                                    <div class="dash-icon purple d-flex flex-column align-items-center">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">perm_contact_calendar</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Profile</div>
                                    </div>
                                    <div class="dash-icon dark-green d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">person</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Users</div>
                                    </div>
                                    <div class="dash-icon sky-blue d-flex flex-column align-items-center">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">group</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Groups</div>
                                    </div>
                                    <div class="dash-icon red d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">settings</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Settings</div>
                                    </div>
                                    <div class="dash-icon light-green d-flex flex-column align-items-center">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">folder</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Document Manager</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tab Navigation for Pagination -->
                        <div class="d-flex justify-content-center mt-3">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link active" id="page1-tab" data-bs-toggle="tab" href="#page1" role="tab">1</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="page2-tab" data-bs-toggle="tab" href="#page2" role="tab">2</a>
                                </li>
                            </ul>
                        </div>

                        <!-- Settings -->
                        <div class="d-flex justify-content-end mt-3 mb-2">
                            <div class="dropdown">
                                <button class="btn btn-link p-0 text-muted" id="linkManagement" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="mts-icon">tune</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="linkManagement">
                                    <li><a class="dropdown-item" href="#">Manage Links</a></li>
                                    <li><a class="dropdown-item" href="#">Add Link</a></li>
                                </ul>
                            </div>
                        </div>
                        
                        <!-- Links -->
                        <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-center g-2 p-2 p-sm-0 p-lg-2">
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">Covid langer Text damit</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">LEER</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">WM Tippspiel</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">WM Tippspiel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Left Column for Form -->
            <div class="col-xl-4 col-lg-5 col-md-12 d-flex flex-column justify-content-center align-items-center order-2 order-lg-1 pe-md-2">
            <div class="scroll-wrapper w-100 h-100">
                <div class="dsb-container w-100 mx-auto">
                    <!-- Headline that appears when columns are NOT wrapping -->
                    <div class="d-none flex-column justify-content-center align-items-center d-lg-flex mb-3 p-2">
                        <!-- Centered Logo -->
                        <div class="logo mb-4">
                            <img [ngSrc]="layoutService.getTheme() === 'dark' ? '/assets/images/logos/humanstarsFullWhite.png':'/assets/images/logos/humanstarsFullGrey.png'" [width]="300" [height]="107" [loaderParams]="{width: 300, height: 107}" [alt]="('HSIMAGE' | translate)" class="img-fluid logo" priority />
                        </div>

                        <!-- Row with Two Equal Columns -->
                        <div class="d-flex align-items-center">
                            <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                                <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h5>Good morning, Clair</h5>
                                <p class="m-0">Tuesday 26, Apr</p>
                            </div>
                        </div>
                    </div>
                    <div class="custom-scrollbar limit-height p-2">
                        <div class="calendar-event w-border card shadow-sm rounded-3 mb-2">
                            <div class="card-body">
                                <!-- Header -->
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <h6 class="m-0">Event Calendar</h6>
                                    <div class="d-flex align-items-center">
                                        <button class="btn btn-link text-muted p-0 me-2">
                                            <div class="mts-icon">chevron_left</div>
                                        </button>
                                        <button class="btn btn-link text-muted p-0 me-3">
                                            <div class="mts-icon">chevron_right</div>
                                        </button>
                                        <div class="d-flex align-items-center">
                                            <button class="btn btn-link text-muted p-0 me-3">
                                                <div class="mts-icon">event</div>
                                                <span>Apr</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            
                                <!-- Days of the Week -->
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <div class="text-center day-container">
                                        <small class="text-muted">Mon</small>
                                        <div class="fw-bold py-1">23</div>
                                        <div class="dots-no-bg">
                                            <span class="dot"></span>
                                        </div>
                                    </div>
                                    <div class="text-center day-container">
                                        <small class="text-muted">Tue</small>
                                        <div class="fw-bold py-1">24</div>
                                        <div class="dots-no-bg">
                                            <span class="dot bg-waring"></span>
                                            <span class="dot bg-success"></span>
                                        </div>
                                    </div>
                                    <div class="text-center day-container">
                                        <small class="text-muted">Wed</small>
                                        <div class="fw-bold py-1">25</div>
                                        <div class="dots-no-bg">
                                            <span class="dot bg-success"></span>
                                        </div>
                                    </div>
                                    <!-- Highlighted Current Day -->
                                    <div class="text-center current-day day-container">
                                        <small class="text-white">Thu</small>
                                        <div class="fw-bold text-white rounded px-2 py-1">26</div>
                                        <div class="dots-container-inverted">
                                            <span class="dot bg-danger"></span>
                                            <span class="dot bg-success"></span>
                                            <span class="dot bg-warning"></span>
                                        </div>
                                    </div>
                                    <div class="text-center day-container">
                                        <small class="text-muted">Fri</small>
                                        <div class="fw-bold py-1">27</div>
                                        <div class="dots-no-bg">
                                            <span class="dot bg-warning"></span>
                                            <span class="dot bg-danger"></span>
                                        </div>
                                    </div>
                                    <div class="text-center day-container">
                                        <small class="text-muted">Sat</small>
                                        <div class="fw-bold py-1">28</div>
                                        <div class="dots-no-bg">
                                            <span class="dot bg-danger"></span>
                                            <span class="dot bg-warning"></span>
                                            <span class="dot bg-success"></span>
                                        </div>
                                    </div>
                                    <div class="text-center day-container">
                                        <small class="text-muted">Sun</small>
                                        <div class="fw-bold py-1">29</div>
                                        <div class="dots-no-bg">
                                            <span class="dot bg-danger"></span>
                                            <span class="dot bg-warning"></span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Options Menu for Days of the Week -->
                                <div class="d-flex justify-content-end">
                                    <div class="dropdown">
                                        <button class="btn btn-link p-0 text-muted" id="event-calendar-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="mts-icon">more_horiz</span>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-calendar-menu">
                                            <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event)">Add Event</a></li>
                                            <li><a class="dropdown-item" href="#">Show all Events</a></li>
                                        </ul>
                                    </div>
                                </div>
                            
                                <!-- Events -->
                                <div class="row eventContainer danger">
                                    <div class="d-flex justify-content-between align-items-center mb-1 mt-3">
                                        <div class="event-details flex-grow-1 d-flex">
                                        <div class="event-bar"></div>
                                        <div class="ms-2">
                                            <h6 class="fw-bold event-title mb-1">Urgent meeting</h6>
                                            <p class="small text-muted mb-0">
                                                Discussing the problems related to the application bugs and clarifying the process of testing the application.
                                            </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="row align-items-center gx-2 ps-3">
                                        <div class="col-auto d-flex align-items-center">
                                            <span class="mts-icon">schedule</span>
                                            <span class="fs-verysmall ps-1">10:00 - 11:30</span>
                                        </div>
                                        <div class="col-auto d-flex align-items-center">
                                            <button class="btn btn-link text-muted d-flex align-items-center p-0 me-3">
                                                <span class="mts-icon">person</span>
                                                <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                            </button>
                                        </div>
                                        <div class="col-auto flex-grow-1 text-end">
                                            <div class="dropdown">
                                                <button class="btn btn-link p-0 text-muted" id="event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="mts-icon">more_horiz</span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-menu">
                                                    <li><a class="dropdown-item" href="#">Share</a></li>
                                                    <li><a class="dropdown-item" href="#">Edit</a></li>
                                                    <li><a class="dropdown-item" href="#">Remove</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow-sm rounded-3 mb-2 w-border recent-notifications" (click)="handleRecentCardClick($event)">
                            <div class="card-body">
                                <div class="d-flex-column">
                                    <div class="d-flex align-items-start">
                                        <div class="d-flex e-avatar e-avatar-circle e-avatar-xlarge">
                                            <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                        </div>
                                        
                                        <div class="col ps-2">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                                <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                            </div>
                                            
                                            <p class="fs-verysmall text-muted mb-1">
                                            Discussing the problems related to the application bugs and clarifying the process of testing the application...
                                            </p>
                                            <div class="row align-items-center gx-2">
                                                <div class="col-auto d-flex align-items-center">
                                                    <span class="badge bg-primary rounded-pill">CHANNELS</span>
                                                </div>
                                                <div class="col-auto flex-grow-1 text-end">
                                                    <div class="dropdown">
                                                        <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span class="mts-icon">more_horiz</span>
                                                        </button>
                                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                                                            <li><a class="dropdown-item" href="#">Mark as read</a></li>
                                                            <li><a class="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>     
                    </div>     
                </div>     
            </div>  
            </div>  
        </div>
    </div>
    <!-- App Store Buttons at the Bottom Right -->
    <div class="app-store-buttons">
        <app-link [href]="'https://play.google.com/store/apps/details?id=com.maxtoolbox.blau'" [target]="'_blank'" [title]="'Google Play'">
        <img [ngSrc]="'/assets/images/logos/google.png'" [alt]="'Google Play'" [width]="134" [height]="40" [loaderParams]="{width: 134, height: 40}" priority />
        </app-link>
        <app-link [href]="'https://apps.apple.com/de/app/humanstars-employee-app/id1343223126'" [target]="'_blank'" [title]="'App Store'">
        <img [ngSrc]="'/assets/images/logos/apple.png'" [alt]="'App Store'" [width]="132" [height]="40" [loaderParams]="{width: 132, height: 40}" priority />
        </app-link>
    </div>

    <!-- Dialog for New Event -->
     <div id="dialog-container">
        <ejs-dialog class="double-dialog" target="#dialog-container" [visible]='visible' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #newEventDialog header="" [position]='position' [isModal]="true" [allowDragging]="false" [width]="'70%'" [height]="'60vh'" [showCloseIcon]="false">
            <ng-template #header class="p-1">
                <div class="steps-list d-flex justify-content-between align-items-center w-100">
                    <!-- Numbers of steps before the current step -->
                    <div class="d-flex justify-content-start flex-shrink-0">
                        <div *ngFor="let step of steps; let i = index" [ngClass]="{
                            'active-step': i === currentStep, 
                            'completed-step': i < currentStep, 
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i < currentStep" class="step-number d-flex justify-content-center align-items-center">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>
                  
                    <!-- Current step and title -->
                    <div class="d-flex flex-grow-1 active-step" >
                      <span class="step-number me-2 d-flex justify-content-center align-items-center">
                        {{ currentStep + 1 }}
                      </span>
                      <span class="step-title">{{ steps[currentStep].title }}</span>
                    </div>
                  
                    <!-- Remaining steps numbers -->
                    <div class="d-flex justify-content-end flex-shrink-0">
                        <div *ngFor="let step of steps; let i = index" [ngClass]="{
                            'active-step': i === currentStep, 
                            'completed-step': i < currentStep, 
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i > currentStep" class="step-number d-flex justify-content-center align-items-center">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>
                  </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex h-100">
                    <!-- Left Column -->
                    <div class="col-md-4 col-12 d-none d-md-flex flex-column left-container p-3">
                        <!-- Row 1: Title -->
                        <div class="text-center mb-3">
                            <h5>Add Event</h5>
                        </div>
                
                        <!-- Row 2: Scrollable Steps List -->
                        <div class="flex-grow-1 overflow-auto">
                            <ul class="steps-list list-unstyled">
                                <li *ngFor="let step of steps; let i = index"
                                    [ngClass]="{
                                        'active-step': i === currentStep, 
                                        'completed-step': i < currentStep, 
                                        'inactive-step': i > currentStep
                                    }"
                                    (click)="goToStep(i)">
                                <span class="step-number">{{ i + 1 }}</span>
                                <span class="step-title">{{ step.title }}</span>
                                </li>
                            </ul>
                        </div>
                
                        <!-- Row 3: Save Button (Hidden on Mobile) -->
                        <div class="save-button-container text-center mt-3 d-none d-md-block">
                        <button class="e-btn e-primary w-100" (click)="save()">Save</button>
                        </div>
                    </div>
                
                    <!-- Right Column -->
                    <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 right-container overflow-auto p-3">
                        <!-- Row 1: Title and Close Button -->
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h5 class="m-0">{{ steps[currentStep].title }}</h5>
                            <!--!!!!!!!!!!!!!THIS CLOSE BUTTON MUST BE WITH THESE CLASSES, BECAUSE ONLY BOOTSTRAP HAS SUCH BUTTON CLASS!!!!!!!!!!!!!-->
                            <button type="button" class="btn btn-close" aria-label="Close" (click)="closeDialog()"></button>
                        </div>
                
                        <!-- Row 2: Scrollable Form Container -->
                        <div class="form-container flex-grow-1 overflow-auto">
                        <form [formGroup]="formGroup" class="p-1">
                            <!-- Dynamic Form Fields -->
                            <label for="title">Title:</label>
                            <input type="text" formControlName="title" class="form-control" id="title">
                        </form>
                        </div>
                
                        <!-- Row 3: Prev/Next Buttons (Hidden on Mobile) -->
                        <div class="navigation-buttons d-flex justify-content-between mt-3 d-none d-md-flex">
                            <button *ngIf="currentStep > 0" class="e-btn e-outline e-secondary w-10" (click)="prevStep()">Prev</button>
                            <button *ngIf="currentStep < steps.length - 1" class="e-btn e-secondary ms-auto w-10" (click)="nextStep()">Next</button>
                        </div>
                    </div>
                    <!-- Row 3 for Mobile: Prev, Save, Next Buttons in Three Columns -->
            
                </div>
            </ng-template>
            <ng-template #footerTemplate>
            <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
                <button *ngIf="currentStep > 0" class="e-btn e-outline e-secondary w-10 mx-1" (click)="prevStep()">Prev</button>
                <button *ngIf="currentStep === 0" class="e-btn e-outline e-secondary invisible w-10">Prev</button> 

                <button class="btn btn-primary mx-auto w-100" (click)="save()">Save</button>

                <button *ngIf="currentStep < steps.length - 1" class="e-btn e-secondary w-10 mx-1" (click)="nextStep()">Next</button>
                <button *ngIf="currentStep === steps.length - 1" class="e-btn e-secondary invisible w-10">Next</button> 
            </div>
            </ng-template>     
        </ejs-dialog>
    </div>
</ng-container>
