import {Component} from '@angular/core';
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {ApiService} from "../../shared/services/api.service";
import {RouterService} from "../../shared/services/router.service";
import {FormBuilder, ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {ErrorService} from "../../shared/services/error.service";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {LayoutService} from "../../shared/services/layout.service";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {ContentComponent} from "../../shared/elements/content/content.component";
import {IconBadgeComponent} from "../../shared/elements/icon-badge/icon-badge.component";
import {BarTopComponent} from "../../shared/elements/bar-top/bar-top.component";
import {BarFooterComponent} from "../../shared/elements/bar-footer/bar-footer.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormInputComponent,
    NgIf,
    NgOptimizedImage,
    ReactiveFormsModule,
    RouterLink,
    TranslatePipe,
    AsyncPipe,
    FormButtonComponent,
    LinkComponent,
    FormTextareaComponent,
    HeadlineComponent,
    ContentComponent,
    IconBadgeComponent,
    BarTopComponent,
    BarFooterComponent
  ],
  providers: [
    TranslatePipe
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {


  public notifications: Object = [
    {
      id: 1,
      title: "Stefan Dragosici",
      content: "Discussing the problems related to the application bugs and clarifying the process of testing...",
      image: "https://ej2.syncfusion.com/demos/src/grid/images/2.png",
      date: "2024-04-15T13:43:00"
    },
    {
      id: 2,
      title: "Stefan Dragosici",
      content: "Another notification about a different task...",
      image: "https://ej2.syncfusion.com/demos/src/grid/images/2.png",
      date: "2024-04-15T13:43:00"
    }
  ];

  public fields: Object = { groupBy: 'date', text: "title", content: "content", image: "image", id: "id" };










  constructor(
    public loadingService: LoadingService,
    private apiService: ApiService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    public languageService: LanguageService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    public userService: UserService,
    public layoutService: LayoutService
  ) {
  }


  public onClick(): void {
    console.log('click');
  }











  public onFakeClick(){

  }

  public onFakeMenuOpen(){

  }








}
