import {HttpInterceptorFn} from "@angular/common/http";
import {inject} from "@angular/core";
import {LoadingService} from "../services/loading.service";
import {finalize} from "rxjs";

export const loadingIndicatorInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);
  loadingService.setLoadingIndicator('increase');
  return next(req).pipe(
    finalize(() => loadingService.setLoadingIndicator('decrease'))
  );
};
