import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {LinkComponent} from "../link/link.component";
import {TranslatePipe} from "../../pipes/translate.pipe";

@Component({
  selector: 'app-warning',
  standalone: true,
  imports: [
    NgIf,
    LinkComponent,
    TranslatePipe
  ],
  templateUrl: './warning.component.html',
  styleUrl: './warning.component.scss'
})
export class WarningComponent {
  @Input() message: string = '';
  @Input() linkText: string = '';
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
}
