import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {ApiService} from "../../shared/services/api.service";
import {RouterService} from "../../shared/services/router.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Config} from "../../shared/config/config";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {ErrorService} from "../../shared/services/error.service";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {LayoutService} from "../../shared/services/layout.service";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {ButtonModule} from '@syncfusion/ej2-angular-buttons';
import {TextBoxModule, NumericTextBoxModule} from '@syncfusion/ej2-angular-inputs';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DialogComponent, ResizeDirections } from '@syncfusion/ej2-angular-popups';
import { CommonModule } from '@angular/common';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule, TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-test',
  standalone: true,
    imports: [
        AsyncPipe,
        FormButtonComponent,
        FormInputComponent,
        FormTextareaComponent,
        HeadlineComponent,
        LinkComponent,
        NgIf,
        NgOptimizedImage,
        ReactiveFormsModule,
        TranslatePipe,
        ButtonModule,
        TextBoxModule,
        TooltipModule,
        ListViewModule,
        DialogModule,
        CommonModule,
        DropDownListModule,
        DatePickerModule,
        TimePickerModule,
        NumericTextBoxModule,
        RadioButtonModule
    ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent implements OnInit, OnDestroy {
  public customerForm: FormGroup = this.formBuilder.group({
    customer: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(45)]]
  });
  public defaultStoreString = Config.DEFAULT_STORE_STRING;
  private subscriptions: Subscription[] = [];
  showNotifications = false;
  showChatNotifications = false;
  //Event Dialog Popup
  @ViewChild('newEventDialog') newEventDialog: DialogComponent | any;
  public position: object={ X: 'center', Y: 'center' };
  public animationSettings: Object = { effect: 'Fade', duration: 400, delay: 0 };
  public resizeHandleDirection: ResizeDirections[] = ['All'];
  public visible: Boolean = false;
  public currentStep = 0;
  steps = [
    { title: 'Event Details' },
    { title: 'Date and Location' },
    { title: 'Guests' }
  ];

  formGroup: FormGroup;

  //Small Filter dropdown
  public statusData: { [key: string]: Object }[] = [
    { text: 'All', id: 'all'},
    { text: 'Wall', id: 'wall' },
    { text: 'Wiki', id: 'channel' },
    { text: 'Feedback', id: 'survey' },
    { text: 'Workflow', id: 'workflow' }
  ];
  public fields: Object = { text: 'text', value: 'id' };
  public selectedValue: string = 'all';

  constructor(
    public loadingService: LoadingService,
    private apiService: ApiService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private userService: UserService,
    public layoutService: LayoutService,
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      
    });
    
  }

  public ngOnInit(): void {
    const customerKey = this.customerService.getStoredCustomerKey(this.route.snapshot.params);
    if (customerKey) {
      this.customerService.setCustomerKey(customerKey);
      this.onCustomerLogin(customerKey);
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public onFakeClick(){

  }

  public onFakeMenuOpen(){

  }

  public handleBellCardClick(event: any): void
  {
    // Check if the click occurred inside the dropdown
    if (event.target.closest('.dropdown')) {
      return; 
    }

    alert('Notification clicked!');
  }

  public handleChatCardClick(event: any): void
  {
    alert('Chat Notification clicked!');
  }

  public handleRecentCardClick(event: any): void
  {
    // Check if the click occurred inside the dropdown
    if (event.target.closest('.dropdown')) {
      return; 
    }

    alert('Recent Notification clicked!');
  }

  // Event handler for dropdown change
  onFilterChange(event: any) {
    console.log('Selected Value:', event.itemData);
    console.log('Selected Text:', event.itemData.text); 
    console.log('Selected ID:', event.itemData.id);
  }

  public onOpenDialog = (event: any): void => {
    event.preventDefault();
    if (this.newEventDialog) {
      this.newEventDialog.show();  // Closes the dialog
    }
  };

  closeDialog(): void {
    if (this.newEventDialog) {
      this.newEventDialog.hide();  // Closes the dialog
    }
  }

  save(): void {
    console.log('Form Data:', this.formGroup.value);
    this.closeDialog();
  }

  // Method to go to a specific step in the dialog
  goToStep(stepIndex: number): void {
    this.currentStep = stepIndex;
  }

  // Method to go to the next step
  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  // Method to go to the previous step
  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  public onOpenNotififcationFilterAllert = function(event: any): void {
    DialogUtility.alert({
      title: 'Notification settings',
      content: "You’ve updated your notification settings. You may not receive all notifications.<br> To adjust, please visit the Settings menu.",
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X:'center',Y:'center'},
      animationSettings: { effect: 'Fade' }
    });
  }
  
  toggleNotificationWindow() {
    this.showNotifications = !this.showNotifications;
    this.showChatNotifications = false;
  }

  toggleChatNotificationWindow() {
    this.showChatNotifications = !this.showChatNotifications;
    this.showNotifications = false;
  }

  public onCustomerLogin(customerKey: string | null): void {
    if (customerKey) {
      this.subscriptions.push(this.apiService.getCustomer(customerKey).subscribe({
        next: (customer) => {
          this.customerService.setCustomer(customer);
          this.languageService.setCustomerLanguages(customer.languages);

          if (customer.api && typeof customer.api != null) {
            this.apiService.setUrl(customer.api);
          }

          if (this.route.snapshot.params.hasOwnProperty('token')) {
            this.userService.setToken(this.route.snapshot.params['token']);
          }
          this.routerService.navigateTo('/auth/login');
        }, error: (error) => {
          this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, false);
        }
      }));
    }
  }

  public setLanguage(language: string): void {
    this.languageService.setLanguage(language);
  }
}
