<div class="card shadow-sm w-border rounded-3 mb-2 bell-notifications">
  <div class="card-body">
    <div class="d-flex-column">
      <div class="d-flex align-items-start">
        <div class="d-flex e-avatar e-avatar-circle e-avatar-xlarge">
          <img [ngSrc]="image" [width]="60" [height]="60" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
        </div>

        <div class="col ps-2">
          <div class="d-flex flex-wrap align-items-center">
            <app-headline [type]="'notification'" [title]="name"></app-headline>
            <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
          </div>

          <p class="fs-verysmall text-muted mb-1" [innerHTML]="message"></p>

          <div class="row align-items-center gx-2">
            <div class="col-auto d-flex align-items-center">
              <span class="badge bg-primary rounded-pill">{{ type.toUpperCase() | translate }}</span>
            </div>
            <div class="col-auto flex-grow-1 text-end">
              <div class="dropdown">
                <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="mts-icon">more_horiz</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                  <li><a class="dropdown-item" href="#">Mark as read</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
