<div class="icon-list-container position-relative">
  <div class="icon-list position-fixed top-0 end-0 p-1">

    <app-icon-badge (click)="setTab('notifications')" [style]="'barTop'" [icon]="'notifications'" [alert]="true" [screenReaderText]="'SRNEWALERTS' | translate"></app-icon-badge>
    <app-icon-badge (click)="setTab('chat')" [style]="'barTop'" [icon]="'chat_bubble'" [alert]="true" [screenReaderText]="'SRNEWMASSAGES' | translate"></app-icon-badge>

    <div class="dropdown d-inline">
      <button type="button" class="btn btn-link p-1 text-muted dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
        <div class="e-avatar e-avatar-circle e-avatar-xsmall">
          <img [ngSrc]="userService.getUser()!.avatar" [width]="24" [height]="24" [sizes]="'100vw, 100vw, 100vw'" [alt]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" priority="low" />
        </div>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><app-link (click)="openPopup('language')" [title]="('LANGUAGE' | translate)" [style]="'dropdown'">{{ ('LANGUAGE' | translate) }}</app-link></li>
        <li><hr class="dropdown-divider"></li>
        <li><app-link (click)="openPopup('profile')" [title]="('PROFILE' | translate)" [style]="'dropdown'">{{ ('PROFILE' | translate) }}</app-link></li>
        <li><app-link (click)="openPopup('settings')" [title]="('SETTINGS' | translate)" [style]="'dropdown'">{{ ('SETTINGS' | translate) }}</app-link></li>
        <li><app-link (click)="openPopup('push')" [title]="('PUSHMESSAGES' | translate)" [style]="'dropdown'">{{ ('PUSHMESSAGES' | translate) }}</app-link></li>
        <li><hr class="dropdown-divider"></li>
        <li><app-link (click)="signOut()" [title]="('SIGNOUT' | translate)" [style]="'dropdown'">{{ ('SIGNOUT' | translate) }}</app-link></li>
      </ul>
    </div>

    <div *ngIf="tab" class="notification-window shadow-lg p-3 d-flex flex-column">
      <div class="d-flex" *ngIf="tab === 'notifications'">
        <app-form-button [text]="'NEW' | translate" [cssClass]="status === 'new' ? 'e-btn e-primary w-100 mx-1' : 'e-btn e-outline e-secondary w-100 mx-1'" (click)="setStatus('new')"></app-form-button>
        <app-form-button [text]="'READ' | translate" [cssClass]="status === 'read' ? 'e-btn e-primary w-100 mx-1' : 'e-btn e-outline e-secondary w-100 mx-1'" (click)="setStatus('read')"></app-form-button>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <app-headline [type]="'h6'" [title]="tab === 'chat' ? ('CHATNOTIFICATIONS' | translate) : ('NOTIFICATIONS' | translate)"></app-headline>
        <div class="dropdown" *ngIf="tab === 'notifications'">
          <app-icon-badge [style]="'barTop'" [icon]="'more_vert'" [screenReaderText]="'SRMOREOPTIONS' | translate" id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false"></app-icon-badge>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
            <li><app-link (click)="setRead()" [title]="('BELLSETALLREAD' | translate)" [style]="'dropdown'">{{ ('BELLSETALLREAD' | translate) }}</app-link></li>
          </ul>
        </div>
      </div>

      <app-warning [message]="'WARNINGNOTIFICATIONS' | translate" [linkText]="'WARNINGNOTIFICATIONSLINK' | translate" (click)="openPopup('push')"></app-warning>

      <div class="row justify-content-end">
        <div class="col-md-6 small-filter-dropdown">
          <ejs-dropdownlist [dataSource]="statusData" [fields]="fields" [placeholder]="'SELECTFILTER' | translate" [sortOrder]="'Ascending'" [value]="selectedValue" [valueTemplate]='valueTemplate' cssClass="filter-dropdown" (change)="onFilterChange($event)" >
            <ng-template #valueTemplate let-data="">
              <div class="d-flex align-items-center text-muted">
                <app-icon-badge [style]="'barTop'" [icon]="'filter_list'" [screenReaderText]="'SRFILTERLIST' | translate"></app-icon-badge>
                <span class='value fs-small'>{{data.text}}</span>
              </div>
            </ng-template>
          </ejs-dropdownlist>
        </div>
      </div>

      <div class="custom-scrollbar">
        <ng-container *ngFor="let item of messages; let i = index">
          <ng-container *ngIf="i === 0 || (item.created_at | date : 'shortDate') !== (messages[(i - 1)].created_at | date : 'shortDate')">
            <app-divider-date [date]="item.created_at"></app-divider-date>
          </ng-container>
          <app-notification [image]="item.image" [name]="item.name" [createdAt]="item.created_at" [message]="item.message" [type]="item.type"></app-notification>
        </ng-container>
      </div>
    </div>
  </div>
</div>
