<div class="content">
  <h1>Page Not Found!</h1>
  <div class="text">
    <p *ngIf="caught">You caught me!</p>
    <p *ngIf="caught">Click "Restart" to continue.</p>
    <p *ngIf="!caught && catchCount === 0">Do something productive instead and catch the moving circle!</p>
    <p>Catch Count: <b>{{ catchCount }}</b></p>
    <app-link (click)="restartGame()" *ngIf="caught" [title]="'Restart'">Restart</app-link>
  </div>
</div>

<div class="game-container">
  <div class="catch-me" [ngStyle]="{'top': objectPosition.top + 'px', 'left': objectPosition.left + 'px'}" (mousedown)="caughtIt()"></div>
</div>
