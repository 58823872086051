import {Component, Input} from '@angular/core';
import {LinkifyPipe} from "../../pipes/linkify.pipe";
import {HeadlineComponent} from "../headline/headline.component";
import {LinkComponent} from "../link/link.component";
import {TranslatePipe} from "../../pipes/translate.pipe";
import {NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-notification',
  standalone: true,
    imports: [
        LinkifyPipe,
        HeadlineComponent,
        LinkComponent,
        TranslatePipe,
        NgOptimizedImage,
        NgIf
    ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {

  @Input() public image: string = '';
  @Input() public name: string = '';
  @Input() public createdAt: Date | null = null;
  @Input() public message: string = '';
  @Input() public type: string = '';

  public openProfilePopup(): void {
    // TODO: implement the profile popup
    console.log('open profile popup');
  }

}
