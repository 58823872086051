import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {CustomerService} from "./customer.service";
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class TitleService extends TitleStrategy {

  constructor(
    private readonly title: Title,
    private readonly customerService: CustomerService,
    private readonly languageService: LanguageService
  ) {
    super();
  }

  updateTitle(routerState: RouterStateSnapshot): void {
    let title = 'HUMANSTARSapp - ';
    if (this.customerService.getCustomer()) {
      title = this.customerService.getCustomer()!.name + ' - ';
    }
    const routeTitle = this.buildTitle(routerState);
    if (routeTitle) {
      title += this.languageService.getTranslationByKey(routeTitle);
    }
    this.title.setTitle(title);
  }
}
