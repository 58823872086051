import {Component} from '@angular/core';
import {NgIf, NgStyle} from "@angular/common";
import {LinkComponent} from "../../../shared/elements/link/link.component";

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    LinkComponent
  ],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
  public objectPosition = {top: 100, left: 100};
  public caught = false;
  public catchCount = 0;
  private speed = 3000;

  public constructor() {
    this.startMoving();
  }

  private startMoving(): void {
    if (!this.caught) {
      this.move();
      setTimeout(() => {
        this.startMoving();
      }, this.speed);
    }
  }

  private move(): void {
    this.objectPosition.top = Math.floor(Math.random() * (600 - 50));
    this.objectPosition.left = Math.floor(Math.random() * (800 - 50));
  }

  public caughtIt(): void {
    if (!this.caught) {
      this.catchCount++;
      this.caught = true;

      switch (this.catchCount) {
        case 1:
          this.speed = 2500;
          break;
        case 2:
          this.speed = 2000;
          break;
        case 3:
          this.speed = 1500;
          break;
        case 4:
          this.speed = 1000;
          break;
        case 5:
          this.speed = 500;
          break;
        case 6:
          this.speed = 250;
          break;
        default:
          this.speed = 100;
      }
    }
  }

  public restartGame(): void {
    this.caught = false;
    this.startMoving();
  }
}
