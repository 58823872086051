import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { setValue, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { ListView, Virtualization } from '@syncfusion/ej2-lists';
const _c0 = ["template"];
const _c1 = ["groupTemplate"];
const _c2 = ["headerTemplate"];
export * from '@syncfusion/ej2-lists';
import { CommonModule } from '@angular/common';
const inputs = ['animation', 'checkBoxPosition', 'cssClass', 'dataSource', 'enable', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enableVirtualization', 'fields', 'groupTemplate', 'headerTemplate', 'headerTitle', 'height', 'htmlAttributes', 'locale', 'query', 'showCheckBox', 'showHeader', 'showIcon', 'sortOrder', 'template', 'width'];
const outputs = ['actionBegin', 'actionComplete', 'actionFailure', 'scroll', 'select'];
const twoWays = [''];
/**
 * Represents Angular ListView Component
 * ```
 * <ejs-listview [dataSource]='data'></ejs-listview>
 * ```
 */
let ListViewComponent = class ListViewComponent extends ListView {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('ListsVirtualization');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
ListViewComponent.ɵfac = function ListViewComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListViewComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ListViewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ListViewComponent,
  selectors: [["ejs-listview"]],
  contentQueries: function ListViewComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    checkBoxPosition: "checkBoxPosition",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enable: "enable",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableVirtualization: "enableVirtualization",
    fields: "fields",
    groupTemplate: "groupTemplate",
    headerTemplate: "headerTemplate",
    headerTitle: "headerTitle",
    height: "height",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    query: "query",
    showCheckBox: "showCheckBox",
    showHeader: "showHeader",
    showIcon: "showIcon",
    sortOrder: "sortOrder",
    template: "template",
    width: "width"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    scroll: "scroll",
    select: "select"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ListViewComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], ListViewComponent.prototype, "template", void 0);
__decorate([Template()], ListViewComponent.prototype, "groupTemplate", void 0);
__decorate([Template()], ListViewComponent.prototype, "headerTemplate", void 0);
ListViewComponent = __decorate([ComponentMixins([ComponentBase])], ListViewComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-listview',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    groupTemplate: [{
      type: ContentChild,
      args: ['groupTemplate']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }]
  });
})();

/**
 * NgModule definition for the ListView component.
 */
class ListViewModule {}
ListViewModule.ɵfac = function ListViewModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListViewModule)();
};
ListViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListViewModule
});
ListViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ListViewComponent],
      exports: [ListViewComponent]
    }]
  }], null, null);
})();
const VirtualizationService = {
  provide: 'ListsVirtualization',
  useValue: Virtualization
};
/**
 * NgModule definition for the ListView component with providers.
 */
class ListViewAllModule {}
ListViewAllModule.ɵfac = function ListViewAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListViewAllModule)();
};
ListViewAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListViewAllModule
});
ListViewAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [VirtualizationService],
  imports: [[CommonModule, ListViewModule], ListViewModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListViewAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ListViewModule],
      exports: [ListViewModule],
      providers: [VirtualizationService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ListViewAllModule, ListViewComponent, ListViewModule, VirtualizationService };
